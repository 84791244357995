/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import DiamondSearchComponent from "../components/DiamondSearch";
import {
  resetDiamondSearch,
  modifyDiamondSearch,
} from "../appRedux/actions/Common";
import { LOCAL_STORAGE_VAR, DIAMOND_WEB_STATUS } from "../constants/Common";
import OpenNotification from "../components/common/CommonButton/OpenNotification";
import {
  handleSearch,
  getSearchList,
  handleSavedSearch,
  handleMatchPair,
  handleSelectSearch,
  getCount,
  handleMultiSearch,
} from "./APIFunctions";
import { LISTINGPAGES } from "../components/DiamondList/DiamondListFunctions";
import Loader from "../components/common/Loader";
import { notification } from "antd";
import {
  concat,
  min,
  max,
  includes,
  uniq,
  flatten,
  join,
  compact,
  groupBy,
  isUndefined,
  uniqBy,
} from "lodash";

class DiamondSearch extends Component {
  state = {
    loading: true,
    diamParams: {},
    selectedMaster: {},
    or: {},
    packetNo: false,
    threeEX: false,
    threeEXNon: false,
    fancyThreeEX: false,
    fancyThreeEXNon: false,
    selectedClrGrps: [],
    selectedClarGrps: [],
    clrSwitch: true,
    claritySwitch: true,
    showSavedSearch: false,
    searchTitle: undefined,
    recentSearchTitle: undefined,
    noBGM: false,
    searchList: [],
    recentSearchList: [],
    stoneIds: "",
    showDemand: false,
    demandTitle: "",
    expiryDate: "",
    isSeal: "",
    searchId: "",
    newArr: false,
    upcoming: false,
    canadamark: false,
    isSVS: false,
    isTrcr: false,
    DORInv: false,
    DORElig: false,
    FMElig: false,
    FMInv: false,
    xRay: false,
    cooStatus: false,
    eyeCln: false,
    wSts: [],
    pairNo: "",
    dorKeys: [],
    fmKeys: [],
    type2: false,
    dvkKeys: [],
    opTbl: "",
    opCrwn: "",
    opPav: "",
    autoShp: "R",
    masterIndex: {},
    multipleSearch: [],
    isRefresh: false,
    reportNo: false,
  };

  componentDidMount = () => {
    let searchId = _.last(_.split(window.location.pathname, "/"));
    this.getData();
    this.setState({ loading: true });
    getSearchList((list) => {
      if (!_.isEmpty(list)) this.setState({ searchList: list });
    });
    getSearchList(
      (list) => {
        if (!_.isEmpty(list)) this.setState({ recentSearchList: list });
      },
      1,
      { page: 1, limit: 10 },
    );
    if (searchId !== LISTINGPAGES.SEARCH) {
      handleSelectSearch(searchId, (searchData, title, type) => {
        if (!_.isEmpty(searchData)) {
          type === 2 &&
            this.setState({ searchTitle: title, searchId, loading: false });
          type === 1 &&
            this.setState({
              recentSearchTitle: title,
              recentSearchId: searchId,
              autoShp: this.props.history.location?.state?.autoShp,
              loading: false,
            });
          this.handleAfterSelectSearch(searchData);
        } else {
          getCount({}, (count) => this.setState({ count, loading: false }));
        }
      });
    } else if (
      this.props.history.location.state &&
      this.props.history.location.state.newArr
    ) {
      this.handleReverseState("newArr");
      this.handleNewArrival();
    } else if (
      this.props.history.location.state &&
      this.props.history.location.state.upcoming
    ) {
      this.handleReverseState("upcoming");
      this.handleUpcoming();
    } else {
      getCount({}, (count) => this.setState({ count, loading: false }));
    }
  };

  componentDidUpdate(prevProps) {
    // Reset props when modify search and click on search
    if (this.props.location !== prevProps.location) {
      this.setState({ loading: true });
      this.handleRefresh();
    }
  }

  getData = () => {
    const masterData = JSON.parse(
      localStorage.getItem(`${LOCAL_STORAGE_VAR}-master`),
    );
    let { diamParams } = this.state;
    this.setState({ loading: true });

    //set min and max from and to carat
    const caratGroupData = uniqBy(masterData.SIZE, "group").map((d) => {
      let groupData = masterData.SIZE.filter(({ group }) => group === d.group);
      d.fromCarat = Math.min(...groupData.map(({ fromCarat }) => fromCarat));
      d.toCarat = Math.max(...groupData.map(({ toCarat }) => toCarat));
      return {
        ...d,
        caratGroup: `${d.fromCarat}-${d.toCarat}`,
      };
    });

    if (masterData) {
      diamParams.shp = masterData.SHAPE;
      diamParams.carat = caratGroupData;
      diamParams.col = masterData.COLOR;
      diamParams.fcCol = masterData.FANCY_COLOR;
      diamParams.clr = masterData.CLARITY;
      diamParams.flu = masterData.FLUORESCENCE;
      diamParams.shd = masterData.SHADE;
      diamParams.lb = masterData.LAB;
      diamParams.cut = masterData.CUT;
      diamParams.pol = masterData.POLISH;
      diamParams.sym = masterData.SYMMETRY;
      diamParams.hA = masterData.H_AND_A;
      diamParams.cult = masterData.CULET;
      diamParams.opTbl = masterData.OPEN_INCLUSION_TABLE;
      diamParams.opPav = masterData.OPEN_INCLUSION_PAVILION;
      diamParams.opCrwn = masterData.OPEN_INCLUSION_CROWN;
      diamParams.grdl = masterData.GIRDLE;
      diamParams.keyToSymbol = masterData.KEY_TO_SYMBOLS;
      diamParams.loc = masterData.LOCATION;
      diamParams.wTbl = masterData.WHITE_INCLUSION_TABLE;
      diamParams.blkTbl = masterData.BLACK_INCLUSION_TABLE;
      diamParams.blkSd = masterData.BLACK_INCLUSION_SIDE;
      diamParams.wSd = masterData.WHITE_INCLUSION_SIDE;
      diamParams.brlncy = masterData.BRILLIANCY;
      diamParams.eCln = masterData.EYECLEAN;
      diamParams.clrGrp = [
        {
          grp: "DEF",
          codes: ["D", "E", "F"],
        },
        {
          grp: "GH",
          codes: ["G", "H"],
        },
        {
          grp: "IJK",
          codes: ["I", "J", "K"],
        },
        {
          grp: "LMN-",
          codes: ["L", "M", "N", "N-"],
        },
      ];
      diamParams.clarGrp = [
        {
          grp: "FL-IF",
          codes: ["FL", "IF"],
        },
        {
          grp: "VVS",
          codes: ["VVS1", "VVS2"],
        },
        {
          grp: "VS",
          codes: ["VS1", "VS2"],
        },
        {
          grp: "SI",
          codes: ["SI1", "SI2", "SI3"],
        },
        {
          grp: "I",
          codes: ["I1", "I2", "I3", "I4"],
        },
      ];
    }
    this.setState({ diamParams });
  };

  handleSelection = (selValues, checked, keyToSend) => {
    let {
      selectedMaster,
      diamParams,
      selectedClrGrps,
      selectedClarGrps,
      masterIndex,
    } = this.state;

    // add for cushionAll
    let cushionAllIds = [];
    let excludedId = [];

    diamParams.shp.map((s) => {
      if (s.key) {
        cushionAllIds = cushionAllIds.concat(s.id);
      }
      if (s.name === "CUSHION ALL") {
        excludedId = excludedId.concat(s.id);
      }
    });

    //this.setState({ loading:true })
    if (selValues === "") {
      _.each(diamParams[keyToSend], function (data) {
        selectedMaster[keyToSend] = _.concat(
          selectedMaster[keyToSend],
          data.id,
        );
      });
    } else {
      selectedMaster[keyToSend] = this.state.selectedMaster[keyToSend] || [];
      selectedMaster[keyToSend] = _.concat(
        selectedMaster[keyToSend],
        selValues,
      );
    }

    if (!checked) {
      selectedMaster[keyToSend] = _.filter(
        this.state.selectedMaster[keyToSend],
        function (val) {
          return selValues.indexOf(val) === -1;
        },
      );
    }

    // unChecked for cushionAll filter
    if (!checked && excludedId.includes(selValues[0])) {
      let shpIds = [];
      diamParams.shp.map((s) => {
        if (s.key) shpIds = shpIds.concat(s.id);
      });
      selectedMaster[keyToSend] = this.state.selectedMaster?.[
        keyToSend
      ]?.filter((id) => !shpIds.includes(id));
    } else if (!checked && selectedMaster?.shp?.includes(excludedId[0])) {
      if (
        !cushionAllIds.every(
          (id) => excludedId.includes(id) || selectedMaster?.shp.includes(id),
        )
      ) {
        selectedMaster["shp"] = this.state.selectedMaster?.[keyToSend]?.filter(
          (val) => !excludedId.includes(val) && selValues.indexOf(val) === -1,
        );
      }
    }

    // add for cushionAll filter
    if (keyToSend === "shp" && checked && excludedId.includes(selValues[0])) {
      let shpIds = selectedMaster["shp"];
      diamParams.shp.map((s) => {
        if (s.key) shpIds = !shpIds.includes(s.id) && uniq(shpIds.concat(s.id));
      });
      selectedMaster["shp"] = shpIds;
    } else if (
      keyToSend === "shp" &&
      checked &&
      selectedMaster?.shp?.length > 0
    ) {
      if (
        cushionAllIds.every(
          (id) => excludedId.includes(id) || selectedMaster?.shp.includes(id),
        )
      ) {
        selectedMaster["shp"] = uniq(selectedMaster?.shp?.concat(excludedId));
      }
    }

    if (keyToSend === "col" || keyToSend === "clr") {
      // To select from groups.
      let cds = this.getCodeFromIds(keyToSend);
      if (keyToSend === "col") {
        diamParams["clrGrp"].map((cg) => {
          if (cg.codes.every((v) => cds.includes(v))) {
            selectedClrGrps.push(cg.grp);
          }
          return true;
        });
      }
      if (keyToSend === "clr") {
        diamParams["clarGrp"].map((cg) => {
          if (cg.codes.every((v) => cds.includes(v))) {
            selectedClarGrps.push(cg.grp);
          }
          return true;
        });
      }
    }

    //getCount(selectedMaster, (count) => this.setState({ count, loading:false}));
    this.setState({
      selectedMaster,
      selectedClrGrps,
      selectedClarGrps,
      masterIndex,
    });
  };

  selectedCarat = (carat, removed = null) => {
    let first;
    let last;
    let or = [];
    let { selectedMaster } = this.state;
    let wholeData = _.groupBy(this.state.diamParams.carat, "group");
    // this.setState({ loading:true })
    if (_.isObject(carat) && !_.isArray(carat)) {
      if (removed && removed.checked === false) {
        or = compact(this.state.or);

        if (removed.range === "") {
          let currG = wholeData[removed.group];
          or = or.filter(
            (x) =>
              x.crt[">="] !== _.head(currG)["fromCarat"] ||
              x.crt["<="] !== _.last(currG)["toCarat"],
          );
        } else if (Array.isArray(removed.range)) {
          removed.range.map((rr) => {
            let first = rr.from;
            let last = rr.to;
            or =
              or &&
              or.filter((x) => x.crt[">="] !== first || x.crt["<="] !== last);
            return true;
          });
        } else {
          let curr = wholeData[removed.range];
          let first = _.head(curr)["fromCarat"];
          let last = _.last(curr)["toCarat"];
          or =
            or &&
            or.filter((x) => x.crt[">="] !== first || x.crt["<="] !== last);
        }
      } else {
        Object.keys(carat).map((key) => {
          carat[key].map((val) => {
            first = _.head(wholeData[key])["fromCarat"];
            last = _.last(wholeData[key])["toCarat"];

            or.push({ crt: { ">=": first, "<=": last } });
            return true;
          });
          return true;
        });
        or = _.uniqWith(or.concat(this.state.or), _.isEqual);
      }
      this.setState({ or: or });
    } else {
      or = this.state.or;
      if (removed) {
        let first = _.head(removed.split("-"));
        let last = _.last(removed.split("-"));
        or = or.filter((x) => x.crt[">="] !== first || x.crt["<="] !== last);
        this.setState({ or: or });
      } else {
        carat.map((val) => {
          first = _.head(val.split("-"));
          last = _.last(val.split("-"));
          if (first !== "" && last !== "") {
            or.push({ crt: { ">=": first, "<=": last } });
          }
          return true;
        });
        or = _.uniqWith(or, _.isEqual);
        this.setState({ or: or });
      }
    }
    selectedMaster["or"] = compact(or);
    //getCount(selectedMaster, (count) => this.setState({ count, loading:false }));
    this.setState({ selectedMaster });
  };

  selectedKeyToSymbol = (selected, key) => {
    //this.setState({ loading:true })
    let { selectedMaster } = this.state;
    let kToSArr = { [key]: selected };
    if (selected.length) {
      selectedMaster["kToSArr"] = kToSArr;
    } else selectedMaster["kToSArr"] = [];
    //getCount(selectedMaster, (count) => this.setState({ count, loading:false }));
    this.setState({ selectedMaster });
  };

  selectedRange = (From, To, master, Min = null, Max = null) => {
    //this.setState({ loading:true })
    let { selectedMaster } = this.state;
    if (From === "" && To === "") {
      delete selectedMaster[master];
    } else if (master === 'back') {
      To = -Math.abs(Number(To));
      From = -Math.abs(Number(From));
      selectedMaster[master] = From > To ? { '>=': To, '<=': From } : { '>=': From, '<=': To };
    } else if ((From === "" || To === "") && (master === 'amt' || master === 'ctPr')) {
      From === '' ? selectedMaster[master] = { '>=': Min, '<=': To }
        : To === '' ? selectedMaster[master] = { '>=': From, '<=': Max }
          : selectedMaster[master] = { '>=': From, '<=': To };
    } else {
      selectedMaster[master] = { '>=': From, '<=': To };
    }
    //getCount(selectedMaster, (count) => this.setState({ count, loading:false }));
    this.setState({ selectedMaster });
  };
  selectedAmount = (From, To, master) => {
    //this.setState({ loading:true })
    let { selectedMaster } = this.state;
    if (From === "" && To === "") {
      delete selectedMaster[master];
    } else {
      Object.assign(selectedMaster, {
        [master]: { ">=": From * 1, "<=": To * 1 },
      });
    }
    //getCount(selectedMaster, (count) => this.setState({ count, loading:false }));
    this.setState({ selectedMaster });
  };

  handleClrGrpSearch = (grp) => {
    let ids = [];
    let { selectedClrGrps, diamParams, selectedMaster } = this.state;
    //this.setState({ loading:true })
    if (!_.includes(this.state.selectedClrGrps, grp)) {
      selectedClrGrps.push(grp);
    } else {
      selectedClrGrps = _.filter(this.state.selectedClrGrps, function (val) {
        return val !== grp;
      });
    }
    selectedClrGrps.length > 0
      ? selectedClrGrps.map((sg) => {
        let { codes } = _.find(diamParams.clrGrp, { grp: sg });
        diamParams.col.map((c) => {
          if (_.includes(codes, c.code)) {
            ids = _.concat(ids, c.id);
          }
          return true;
        });
        selectedMaster["col"] = ids;
        return true;
      })
      : delete selectedMaster["col"];

    //getCount(selectedMaster, (count) => this.setState({ count, loading:false }));
    this.setState({ selectedClrGrps, selectedMaster });
  };

  handleClarityGrpSearch = (grp) => {
    let ids = [];
    let { selectedClarGrps, diamParams, selectedMaster } = this.state;
    //this.setState({ loading:true })
    if (!_.includes(this.state.selectedClarGrps, grp)) {
      selectedClarGrps.push(grp);
    } else {
      selectedClarGrps = _.filter(this.state.selectedClarGrps, function (val) {
        return val !== grp;
      });
    }
    selectedClarGrps.length > 0
      ? selectedClarGrps.map((sg) => {
        let { codes } = _.find(diamParams.clarGrp, { grp: sg });
        diamParams.clr.map((c) => {
          if (_.includes(codes, c.code)) {
            ids = _.concat(ids, c.id);
          }
          return true;
        });
        selectedMaster["clr"] = ids;
        return true;
      })
      : delete selectedMaster["clr"];
    //getCount(selectedMaster, (count) => this.setState({ count, loading:false }));
    this.setState({ selectedClarGrps, selectedMaster });
  };

  handleClrSwitch = () => {
    let { selectedMaster } = this.state;
    //this.setState({ loading:true })
    //getCount(selectedMaster, (count) => this.setState({ count, loading:false }));
    this.setState({
      selectedMaster,
      clrSwitch: !this.state.clrSwitch,
    });
  };

  handleClarSwitch = () => {
    let { selectedMaster } = this.state;
    //this.setState({ loading:true })
    //getCount(selectedMaster, (count) => this.setState({ count, loading:false }));
    this.setState({
      selectedMaster,
      claritySwitch: !this.state.claritySwitch,
    });
  };

  handleRefresh = () => {
    this.props.resetDiamondSearch({ searchReset: true });
    this.setState({
      loading: true,
      selectedMaster: {},
      or: [],
      threeEX: false,
      threeEXNon: false,
      fancyThreeEX: false,
      fancyThreeEXNon: false,
      selectedClrGrps: [],
      selectedClarGrps: [],
      clrSwitch: true,
      claritySwitch: true,
      showSavedSearch: false,
      searchTitle: undefined,
      recentSearchTitle: undefined,
      noBGM: false,
      // searchList: [],
      stoneIds: "",
      showDemand: false,
      demandTitle: "",
      expiryDate: "",
      isSeal: "",
      searchId: "",
      newArr: false,
      upcoming: false,
      canadamark: false,
      isSVS: false,
      isTrcr: false,
      DORInv: false,
      DORElig: false,
      FMElig: false,
      FMInv: false,
      xRay: false,
      cooStatus: false,
      eyeCln: false,
      wSts: [],
      pairNo: "",
      dorKeys: [],
      fmKeys: [],
      type2: false,
      dvkKeys: [],
      opTbl: "",
      opCrwn: "",
      opPav: "",
      autoShp: "R",
      masterIndex: {},
      multipleSearch: [],
      isRefresh: true,
    });
    getCount({}, (count) =>
      this.setState({ count, isRefresh: false, loading: false }),
    );
  };

  handleSetStoneIds = async (blurEvent) => {
    let { selectedMaster } = this.state;
    // this.setState({ loading:true })
    let or = [];
    let arr = this.state.stoneIds?.includes(",")
      ? _.split(this.state.stoneIds, ",")
      : this.state.stoneIds?.includes(" ")
        ? _.split(this.state.stoneIds, " ")
        : this.state.stoneIds;

    arr = _.isArray(arr) ? arr.map((a) => _.trim(a)) : [this.state.stoneIds];

    _.map(arr, (a, i) => {
      if (a === "" || _.isEmpty(a) || a === " ") {
        _.pullAt(arr, [i]);
      }
      return true;
    });
    arr.length && or.push({ vStnId: arr });
    arr.length && or.push({ rptNo: arr });
    arr.length && or.push({ certNos: { in: arr } });
    selectedMaster["or"] = arr.length ? or : or;
    // selectedMaster["or"] = arr.length ? (selectedMaster["or"] ? concat(or, selectedMaster["or"]) : or) : or;
    if (
      selectedMaster.or &&
      selectedMaster.or[0] &&
      selectedMaster.or[0].vStnId
    ) {
      await getCount(selectedMaster, (count) => {
        this.setState({ count, loading: false });
        if (
          selectedMaster.or &&
          selectedMaster.or[0] &&
          selectedMaster.or[0].vStnId &&
          count === 0
        ) {
          notification.destroy();
          if (!blurEvent) {
            OpenNotification({
              type: "error",
              title:
                "Sorry, the stone id(s) you have entered is not found. Please try again.",
            });
            this.setState({
              showDemand: false,
              loading: false,
              reportNo: true,
            });
          }
        } else {
          this.setState({ packetNo: true, reportNo: false });
        }
      });
    } else {
      this.setState({ showDemand: false, loading: false, reportNo: false });
      notification.destroy();
      // OpenNotification({
      //   type: 'error',
      //   title: 'Please enter stone ID or report number.',
      // });
      //getCount(selectedMaster, (count) => this.setState({ count, loading:false }));
    }
    this.setState({ selectedMaster });
  };

  handleSetPairs = () => {
    let { selectedMaster } = this.state;
    //this.setState({ loading:true })
    let or = [];
    let arr = this.state.pairNo.includes(",")
      ? _.split(this.state.pairNo, ",")
      : this.state.pairNo.includes(" ")
        ? _.split(this.state.pairNo, " ")
        : this.state.pairNo;

    arr = _.isArray(arr) ? arr.map((a) => _.trim(a)) : [this.state.pairNo];

    _.map(arr, (a, i) => {
      if (a === "" || _.isEmpty(a) || a === " ") {
        _.pullAt(arr, [i]);
      }
      return true;
    });
    arr.length && or.push({ pairStkNo: arr });

    selectedMaster["or"] = or;
    //getCount(selectedMaster, (count) => this.setState({ count, loading:false }));
    this.setState({ selectedMaster });
  };

  handleChange = (key, value) => {
    this.setState({ [key]: value });
  };

  handleAfterSelectSearch = (searchData) => {
    let { diamParams, masterIndex } = this.state;
    let caratSizeGroup = groupBy(diamParams.carat, "group");
    this.setState({ loading: true });

    let wSts = searchData.wSts || [];
    let eyeCln = searchData.eCln ? true : false;
    let upcoming =
      searchData.wSts &&
      _.includes(searchData.wSts, DIAMOND_WEB_STATUS.UPCOMING);
    let newArr =
      searchData.wSts && _.includes(searchData.wSts, DIAMOND_WEB_STATUS.BID);
    let canadamark = searchData.isCm ? true : false;
    let isSVS = searchData.isSVS ? true : false;
    let isTrcr = searchData.isTrcr ? true : false;
    let type2 = searchData.type2 ? true : false;
    let xRay = searchData.isXray;
    let cooStatus = searchData.cooStatus;
    let dorKeys = searchData.isDor || [];
    let dvkKeys = searchData.isDiavik || [];
    let fmKeys = searchData.isFm || [];

    let opTbl =
      searchData.opTbl &&
      _.find(diamParams["opTbl"], { id: searchData.opTbl }).code;
    let opCrwn =
      searchData.opCrwn &&
      _.find(diamParams["opCrwn"], { id: searchData.opCrwn }).code;
    let opPav =
      searchData.opPav &&
      _.find(diamParams["opPav"], { id: searchData.opPav }).code;
    let isSeal =
      searchData.isSeal === true
        ? "YES"
        : searchData.isSeal === false
          ? "NO"
          : "";
    let threeEX = searchData.excludeFilter && searchData.excludeFilter.threeEX;
    let threeEXNon =
      searchData.excludeFilter && searchData.excludeFilter.threeEXNon;
    let fancyThreeEX =
      searchData.excludeFilter && searchData.excludeFilter.fancyThreeEX;
    let fancyThreeEXNon =
      searchData.excludeFilter && searchData.excludeFilter.fancyThreeEXNon;
    let noBGM = searchData.excludeFilter && searchData.excludeFilter.noBGM;

    let caratRange = [];
    let customeCarat = [];

    Object.entries(caratSizeGroup).map((carat, index) => {
      return searchData?.or?.map((s) => {
        if (
          s?.crt?.[">="] === carat?.[1]?.[0]?.["fromCarat"] &&
          s?.crt?.["<="] === carat?.[1]?.[0]?.["toCarat"]
        ) {
          caratRange = caratRange?.concat(s);
          masterIndex["crt"] = masterIndex["crt"]
            ? masterIndex["crt"].concat(index)
            : [index];
        }
      });
    });

    customeCarat = searchData?.or?.filter((condition) => {
      if (condition && condition.crt) {
        return !diamParams.carat.find(
          (carat) =>
            carat.fromCarat === condition.crt[">="] &&
            carat.toCarat === condition.crt["<="],
        );
      }
    });

    getCount(searchData, (count) => this.setState({ count, loading: false }));

    this.props.modifyDiamondSearch({ modifyData: searchData });
    this.setState({
      masterIndex,
      selectedMaster: searchData,
      or: { carat: caratRange, customeCarat },
      stoneIds: searchData.or ? searchData.or[0].vStnId : null,
      wSts,
      eyeCln,
      upcoming,
      canadamark,
      isSVS,
      isTrcr,
      type2,
      xRay,
      cooStatus,
      dorKeys,
      dvkKeys,
      fmKeys,
      newArr,
      opTbl,
      opPav,
      opCrwn,
      isSeal,
      noBGM,
      threeEXNon,
      threeEX,
      fancyThreeEXNon,
      fancyThreeEX,
    });
  };

  handleReverseState = (state) => {
    let updateState = {};
    //clear demand popup value on submit
    if (state === "showDemand") {
      updateState = { demandTitle: "", expiryDate: "" };
    }

    this.setState({ [state]: !this.state[[state]], ...updateState });
  };

  handleCombineSearch = (opt, checked, multiple) => {
    //this.setState({ loading:true })
    let excludeFilter = {};
    let { selectedMaster, diamParams } = this.state;
    let filter = {
      threeEX: {
        cut: ["EX"],
        pol: ["EX"],
        sym: ["EX"],
      },
      threeEXNon: {
        cut: ["I", "EX", "NONE"],
        pol: ["I", "EX", "NONE"],
        sym: ["I", "EX", "NONE"],
        flu: ["I", "EX", "VG", "NON"],
      },
      fancyThreeEX: {
        cut: [],
        pol: ["EX"],
        sym: ["EX"],
      },
      fancyThreeEXNon: {
        cut: ["I", "NONE"],
        pol: ["I", "EX", "NONE"],
        sym: ["I", "EX", "NONE"],
        flu: ["I", "EX", "VG", "NON"],
      },
      noBGM: {
        shd: ["WHT", "YEL", "NONE", "OWH", "LYL"],
        brlncy: ["EX", "VG"],
      },
      milky: {
        mlk: ["ML1", "ML2", "ML3"],
      },
      eyeCln: {
        eCln: ["Y"],
      },
    };

    if (checked) {
      Object.keys(filter[opt]).map((k) => {
        let ids = [];
        diamParams[k].map((d2) => {
          if (_.includes(filter[opt][k], d2.code)) {
            ids = _.concat(ids, d2.id);
          }
          return true;
        });
        selectedMaster[k] = ids;
        return true;
      });
      excludeFilter[opt] = checked;
    } else {
      Object.keys(filter[opt]).map((d1) => {
        selectedMaster[d1] = [];
        return true;
      });
      excludeFilter = _.omit(excludeFilter, opt);
    }

    if (!multiple) {
      _.keys(filter).map((k) => {
        if (k !== opt) {
          this.setState({ [k]: false }); // Other keys will be false at a time.
        }
        return true;
      });
    }

    selectedMaster.excludeFilter = excludeFilter;

    //getCount(selectedMaster, (count) => this.setState({ count, loading:false }));
    this.setState({
      selectedMaster,
      [opt]: !this.state[[opt]],
    });
  };

  handleUpcoming = () => {
    let { wSts, selectedMaster } = this.state;
    //this.setState({ loading:true })

    if (!this.state.upcoming) {
      wSts = _.concat(wSts, [DIAMOND_WEB_STATUS.UPCOMING]);
      wSts = _.filter(wSts, function (val) {
        return val !== DIAMOND_WEB_STATUS.BID; // New Arrival false when upcoming selected.
      });
    } else if (this.state.upcoming)
      wSts = _.filter(wSts, function (val) {
        return val !== DIAMOND_WEB_STATUS.UPCOMING;
      });
    selectedMaster.wSts = wSts;
    this.setState({ selectedMaster, wSts, newArr: false, loading: false });
    // getCount(selectedMaster, (count) => this.setState({ count, loading:false }));
  };

  handleCanadamark = () => {
    let { selectedMaster } = this.state;
    //this.setState({ loading:true })
    if (!this.state.canadamark) selectedMaster.isCm = ["CERT", "ELIG"];
    else delete selectedMaster.isCm;
    this.setState({ selectedMaster });
    //getCount(selectedMaster, (count) => this.setState({ count, loading:false }));
  };

  handleXray = () => {
    let { selectedMaster } = this.state;
    //this.setState({ loading:true })
    if (!this.state.xRay) selectedMaster.isXray = true;
    else delete selectedMaster.isXray;
    this.setState({ selectedMaster });
    //getCount(selectedMaster, (count) => this.setState({ count, loading:false }));
  };

  handleCooStatus = () => {
    let { selectedMaster } = this.state;
    if (!this.state.cooStatus) selectedMaster.cooStatus = ["CERT", "INSC"];
    else delete selectedMaster.cooStatus;
    this.setState({ selectedMaster });
  };

  //SVS//
  handlesvsStatus = () => {
    const { selectedMaster } = this.state;
    if (!this.state.isSVS) selectedMaster.isSVS = ["CERT", "ELIG"];
    else delete selectedMaster?.isSVS;
    this.setState({ selectedMaster });
  };
  //TRA//

  handleTraStatus = () => {
    const { selectedMaster } = this.state;
    if (!this.state.isTrcr) selectedMaster.isTrcr = ["CERT", "ELIG"];
    else delete selectedMaster.isTrcr;
    this.setState({ selectedMaster });
  };

  handleType2 = () => {
    let { selectedMaster } = this.state;
    //this.setState({ loading:true })
    if (!this.state.type2) selectedMaster.type2 = { nin: [""] };
    else delete selectedMaster.type2;
    this.setState({ selectedMaster });
    //getCount(selectedMaster, (count) => this.setState({ count, loading:false }));
  };

  handleNewArrival = () => {
    let { selectedMaster, wSts } = this.state;
    //this.setState({ loading:true })

    if (!this.state.newArr) {
      wSts = _.concat(wSts, [DIAMOND_WEB_STATUS.BID]);
      wSts = _.filter(wSts, function (val) {
        return val !== DIAMOND_WEB_STATUS.UPCOMING; // Upcoming false when new arrival selected.
      });
    } else if (this.state.newArr)
      wSts = _.filter(wSts, function (val) {
        return val !== DIAMOND_WEB_STATUS.BID;
      });
    selectedMaster.wSts = wSts;

    this.setState({ upcoming: false, selectedMaster, wSts });
    //getCount(this.state.selectedMaster, (count) => this.setState({ count, loading:false }));
  };

  handleLab = (status, key, inKey) => {
    let { selectedMaster, fmKeys, dorKeys, dvkKeys } = this.state;
    //this.setState({ loading:true })
    if (status) {
      if (key === "isDor") {
        dorKeys = _.concat(dorKeys, inKey);
        selectedMaster[key] = dorKeys;
      }
      if (key === "isFm") {
        fmKeys = _.concat(fmKeys, inKey);
        selectedMaster[key] = fmKeys;
      }
      if (key === "isDiavik") {
        dvkKeys = _.concat(dvkKeys, inKey);
        selectedMaster[key] = dvkKeys;
      }
    } else {
      selectedMaster[key] = _.filter(selectedMaster[key], function (val) {
        return val !== inKey;
      });
      if (key === "isDor") {
        dorKeys = selectedMaster[key];
      }
      if (key === "isFm") {
        fmKeys = selectedMaster[key];
      }
      if (key === "isDiavik") {
        dvkKeys = selectedMaster[key];
      }
    }
    //getCount(selectedMaster, (count) => this.setState({ count, loading:false }));
    this.setState({ selectedMaster, dorKeys, fmKeys, dvkKeys });
  };

  handleWebStatus = (value) => {
    let { wSts, selectedMaster } = this.state;
    //this.setState({ loading:true })
    if (!_.includes(wSts, value)) {
      wSts = _.concat(wSts, value);
    } else {
      wSts = _.filter(wSts, (val) => {
        return val !== value;
      });
    }
    selectedMaster["wSts"] = wSts;
    //getCount(selectedMaster, (count) => this.setState({ count, loading:false }));
    this.setState({ wSts, selectedMaster });
  };

  getCodeFromIds = (master) => {
    let { selectedMaster, diamParams } = this.state;
    let ids = selectedMaster[master];
    let cds = [];
    diamParams[master].map((ms) => {
      if (_.includes(ids, ms.id[0])) {
        cds = _.concat(cds, ms.code);
      }
      return true;
    });
    return cds;
  };

  handleToggle = (key, checked) => {
    let { selectedMaster, diamParams } = this.state;
    //this.setState({ loading:true })
    if (key === "isSeal") {
      let seal = false;
      seal = checked === "YES" ? true : checked === "NO" ? false : "";
      selectedMaster[key] = seal;
      if (seal === "") delete selectedMaster[key];
    } else {
      let id = _.find(diamParams[key], { code: checked })
        ? _.find(diamParams[key], { code: checked }).id
        : [];
      selectedMaster[key] = id;
    }

    //getCount(selectedMaster, (count) => this.setState({ count, loading:false }));
    this.setState({ selectedMaster });
  };

  handleAutoShapeSelection = (shp) => {
    let { diamParams, selectedMaster } = this.state;
    //this.setState({ loading:true })

    if (shp === "R") {
      let shpIds = [];
      diamParams.shp.map((s) => {
        if (s.code === "RD") {
          shpIds = shpIds.concat(s.id);
        }
      });
      selectedMaster["shp"] = shpIds;
    } else {
      let shpIds = [];
      diamParams.shp.map((s) => {
        if (s.code !== "RD") {
          shpIds = shpIds.concat(s.id);
        }
      });
      selectedMaster["shp"] = shpIds;
    }
    //getCount(selectedMaster, (count) => this.setState({ count, loading:false }));
    this.setState({ selectedMaster, autoShp: shp });
  };

  handleMasterRangeSelection = (index, keyToSend) => {
    let { selectedMaster, masterIndex, diamParams } = this.state;
    //this.setState({ loading:true })

    let masters = [];
    let inBetween = masterIndex[keyToSend] || [];
    if (
      includes(inBetween, index) &&
      (index === min(inBetween) || index === max(inBetween))
    ) {
      inBetween = _.filter(inBetween, (val) => {
        return val !== index;
      });
    } else {
      inBetween.push(index);
    }
    let start = min(inBetween);
    let end = max(inBetween);
    inBetween = inBetween.length
      ? Array(end - start + 1)
        .fill()
        .map((_, idx) => start + idx)
      : [];

    masterIndex[keyToSend] = inBetween;
    diamParams[keyToSend].map((d, index) => {
      if (includes(inBetween, index)) masters.push(d.id);
    });
    selectedMaster[keyToSend] = uniq(flatten(masters));
    //getCount(selectedMaster, (count) => this.setState({ count, loading:false }));
    this.setState({ selectedMaster, masterIndex });
  };

  handleMutipleSearch = () => {
    let { multipleSearch, selectedMaster } = this.state;
    multipleSearch.push(selectedMaster);
    this.handleRefresh();
    this.setState({ multipleSearch });
  };

  __handleSearch = () => {
    let { selectedMaster } = this.state;
    handleSearch(selectedMaster, (cb) => {
      if (cb.NOSTONE === "NOSTONE") {
        this.setState({ loading: false });
        this.setState({ showDemand: true });
      } else {
        if (this.state.newArr || cb.isNewArrival) {
          this.props.history.push(`/${LISTINGPAGES.BID}?${cb.id}`);
          return;
        }
        if (this.state.upcoming) {
          this.props.history.push(`/${LISTINGPAGES.UPCOMING}`);
          return;
        }
        this.setState({ loading: true });
        this.props.history.push(`/${LISTINGPAGES.LIST}?${cb.id}`, {
          autoShp: this.state.autoShp,
        });
      }
    });
  };

  handleMultipleSearchApiCall = () => {
    let { multipleSearch } = this.state;
    handleMultiSearch(multipleSearch, (success) => {
      this.props.history.push(
        `/${LISTINGPAGES.LIST}?${join(success.ids, ",")}`,
      );
    });
  };

  handleAddCustomCarat = (arr) => {
    let { selectedMaster, or } = this.state;
    // this.setState({ loading:true })
    let newArr = [];
    Object.keys(or).map((o) => {
      if (!isUndefined(or[o]) && or[o].length) {
        or[o].map((i) => o !== "customeCarat" && newArr.push(i));
      }
    });

    selectedMaster["or"] = newArr;
    selectedMaster["or"] = selectedMaster["or"].concat(...arr);
    getCount(selectedMaster, (count) =>
      this.setState({ count, loading: false }),
    );
    this.setState({
      selectedMaster,
      or: { ...this.state.or, customeCarat: arr },
    });
  };

  handleCaratSelection = (index) => {
    // To general selection without range.
    let { diamParams, or, selectedMaster, masterIndex = {} } = this.state;
    let caratData = groupBy(diamParams.carat, "group");
    //this.setState({ loading:true })

    masterIndex["crt"] = masterIndex["crt"] ? masterIndex["crt"] : [];

    if (includes(masterIndex["crt"], index))
      masterIndex["crt"] = masterIndex["crt"].filter((f) => f !== index);
    else masterIndex["crt"].push(index);

    let caratRange = [];
    let newArr = [];
    Object.keys(or).map((o) => {
      if (!isUndefined(or[o]) && or[o].length) {
        or[o].map((i) => {
          if (o !== "carat") newArr.push(i);
        });
      }
    });
    selectedMaster["or"] = newArr;

    masterIndex["crt"].map((i) => {
      let fromCarat = caratData[Object.keys(caratData)[i]][0]["fromCarat"];
      let toCarat = caratData[Object.keys(caratData)[i]][0]["toCarat"];
      caratRange.push({
        crt: { ">=": fromCarat, "<=": toCarat },
      });
    });

    selectedMaster["or"] = selectedMaster["or"].concat(...caratRange);
    //getCount(selectedMaster, (count) => this.setState({ count, loading:false }));
    this.setState({ or: { ...this.state.or, carat: caratRange }, masterIndex });
  };

  handleMta = () => {
    const { selectedMaster } = this.state;
    //this.setState({ loading:true })
    if (!this.state.isArtisanCerti) selectedMaster.isArtisanCerti = true;
    else delete selectedMaster.isArtisanCerti;
    this.setState({ selectedMaster });
    //getCount(selectedMaster, (count) => this.setState({ count, loading:false }));
  };

  render() {
    return (
      <>
        {this.state.loading ? <Loader loading={true} fixed /> : null}
        <DiamondSearchComponent
          {...this.state}
          handleSelection={this.handleSelection}
          selectedCaratFn={this.selectedCarat}
          handleSearch={this.__handleSearch}
          handleSavedSearch={() =>
            handleSavedSearch(
              this.state.selectedMaster,
              this.state.searchTitle,
              this.state.searchId,
              (id) => {
                if (id) {
                  this.setState({ showSavedSearch: false, searchId: "" });
                  this.props.history.push(`/${LISTINGPAGES.LIST}?` + id);
                }
              },
            )
          }
          handleMatchPair={() => {
            this.setState({ loading: true });
            return handleMatchPair(
              this.state.selectedMaster,
              (id) =>
                id &&
                this.props.history.push(`/${LISTINGPAGES.MATCHPAIR}?` + id),
            );
          }}
          selectedKeyToSymbol={this.selectedKeyToSymbol}
          selectedRange={this.selectedRange}
          selectedAmount={this.selectedAmount}
          handleClrGrpSearch={this.handleClrGrpSearch}
          handleClarityGrpSearch={this.handleClarityGrpSearch}
          handleClrSwitch={this.handleClrSwitch}
          handleClarSwitch={this.handleClarSwitch}
          handleRefresh={this.handleRefresh}
          handleChange={this.handleChange}
          handleSetStoneIds={this.handleSetStoneIds}
          handleAfterSelectSearch={this.handleAfterSelectSearch}
          isFancy={
            _.split(window.location.pathname, "/")[1] ===
              LISTINGPAGES.FANCY_SEARCH
              ? true
              : false
          }
          handleReverseState={this.handleReverseState}
          handleCombineSearch={this.handleCombineSearch}
          handleSetPairs={this.handleSetPairs}
          handleWebStatus={this.handleWebStatus}
          handleLab={this.handleLab}
          handleUpcoming={this.handleUpcoming}
          handleType2={this.handleType2}
          handleXray={this.handleXray}
          handleCooStatus={this.handleCooStatus}
          handlesvsStatus={this.handlesvsStatus}
          handleTraStatus={this.handleTraStatus}
          handleCanadamark={this.handleCanadamark}
          handleNewArrival={this.handleNewArrival}
          handleToggle={this.handleToggle}
          handleAutoShapeSelection={this.handleAutoShapeSelection}
          handleMasterRangeSelection={this.handleMasterRangeSelection}
          handleMutipleSearch={this.handleMutipleSearch}
          handleMultipleSearchApiCall={this.handleMultipleSearchApiCall}
          handleAddCustomCarat={this.handleAddCustomCarat}
          handleCaratSelection={this.handleCaratSelection}
          handleMta={this.handleMta}
        />
      </>
    );
  }
}

const mapStateToProps = (props) => {
  return props;
};

export default connect(mapStateToProps, {
  resetDiamondSearch,
  modifyDiamondSearch,
})(DiamondSearch);
