/* eslint-disable */
import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setLoader, covidStrip } from "../appRedux/actions/Common";
import _ from "lodash";
import UtilService from "../services/util";
import {
  commonLogout,
  LOCAL_STORAGE_VAR,
  BASE_URL_ATTACH,
} from "../constants/Common";
import Sidebar from "./Sidebar";
import HeaderRightSidebar from "./common/HeaderRightSidebar";
import NotificationDropdown from "./common/Header/NotificationDropdown";
import HeaderSearch from "./common/Header/Search";
import HeaderStrip from "./common/Header/HeaderStrip";
import CovidStrip from "./common/Header/CovidStrip";
import CountBox from "./common/CountBox";
import IntlMessages from "../util/IntlMessages";
import { GET_DASHBOARD_DATA, GET_EVENTS } from "../constants/apiConstant";
import { getSearchList } from "../containers/APIFunctions";
import DefaultUser from "./common/UserProfileDefault";
import { GuestUserPopup } from "./GuestUserPopup";
import SelectOption from "./common/SelectOption";
import CommonModal from "../components/common/CommonModal";
import InputBlock from "../components/common/InputBlock";
import OpenNotification from "../components/common/CommonButton/OpenNotification";
import HeaderAccountDetails from "./common/HeaderAccountList/HeaderAccountDetails";

class Header extends Component {
  // new Code
  constructor(props) {
    super(props);
    this.state = {
      isHovered: false,
      isHovered1: false,
      isHovered2: false,
      covid: true,
      search: false,
      details: {},
      HeaderStripList: true,
      counts: {},
      rates: {},
      notifications: 0,
      event: [],
      searchList: [],
      isGuest: localStorage.getItem(`${LOCAL_STORAGE_VAR}-guest`),
      showGuest: false,
      selectPriceType: undefined,
      priceInput: "",
      updatePrice: false,
      // notifyData: [],
    };
  }
  componentDidUpdate(prevProps) {
    if (
      this.props.data.deleteReminder.count !==
      prevProps.data.deleteReminder.count
    ) {
      // this.getSidebarCounts();
    }
  }
  componentDidMount() {
    this.getSidebarCounts();
    this.getEvents();
    this.props.covidStrip(this.state.covid);
    getSearchList((list) => this.setState({ searchList: list }));
  }
  closeStrip = () => {
    this.props.covidStrip(!this.props.data.commonData.strip);
    this.setState({
      covid: false,
    });
  };
  searchOption = () => {
    if (!this.state.isGuest) {
      this.setState({
        search: !this.state.search,
      });
    } else {
      this.setState({
        showGuest: true,
      });
    }
  };
  showGuest = () => {
    this.setState({ showGuest: true });
  };
  handleHover = () => {
    this.setState({
      isHovered: !this.state.isHovered,
      isHovered1: false,
      isHovered2: false,
    });
  };
  handleHover1 = () => {
    this.setState({
      isHovered1: !this.state.isHovered1,
      isHovered: false,
      isHovered2: false,
    });
  };
  handleHover2 = () => {
    this.setState({
      isHovered2: !this.state.isHovered2,
      isHovered: false,
      isHovered1: false,
    });
  };
  handleOut = () => {
    this.setState({
      isHovered: false,
    });
  };
  handleOut1 = () => {
    this.setState({
      isHovered1: false,
    });
  };
  handleOut2 = () => {
    this.setState({
      isHovered2: false,
    });
    // this.MarkAsRead();
  };

  HeaderStripCick = () => {
    this.props.covidStrip(!this.props.data.commonData.strip);
    this.setState({
      HeaderStripList: !this.state.HeaderStripList,
    });
  };
  getSidebarCounts = () => {
    let self = this;
    let obj = {
      savedSearch: false,
      recentSearch: false,
      track: false,
      orderData: false,
      dashboardCount: false,
      shippingCount: false,
      seller: false,
      account: false,
      rates: true,
    };
    let api = GET_DASHBOARD_DATA.url;
    let objData = {
      method: GET_DASHBOARD_DATA.method,
      url: api,
      request: obj,
    };
    UtilService.callApi(objData, async function (err, data) {
      if (err) throw err;
      if (data && data.code === "OK") {
        self.setState({
          // details: data.data.tracks,
          rates: data.data.rateObj,
          // counts: _.omit(data.data, "tracks"),
        });
      }
    });
  };

  getEvents = () => {
    let self = this;
    let obj = {
      ...GET_EVENTS,
      request: {
        page: 1,
        limit: 10,
        type: 6,
        filter: {
          isDeleted: false,
          isActive: true,
        },
      },
    };
    UtilService.callApi(obj, function (err, data) {
      if (err) {
      } else if (data && data.code === "OK") {
        if (data.data) {
          let eventData = data.data.list[0];

          self.setState({
            event: eventData,
          });
        }
      } else {
        //
      }
    });
  };

  // MarkAsRead = () => {
  //   let self = this;
  //   let obj = {
  //     ...MARK_AS_READ,
  //     // request: {
  //     //   id: id,
  //     // },
  //   };
  //   UtilService.callApi(obj, function (err, data) {
  //     if (err) {
  //       //
  //     } else if (data && data.code === "OK") {
  //       if (data.data) {
  //         //setData(data.data.list);
  //         self.setState({
  //           notifications: 0,
  //         });
  //       }
  //     } else {
  //       //setData(undefined);
  //     }
  //   });
  // };

  render() {
    // new Code
    const btnClass = this.state.isHovered ? "active" : "";
    const btnClass1 = this.state.isHovered1 ? "active" : "";
    const btnClass2 = this.state.isHovered2 ? "active" : "";
    // const name = JSON.parse(localStorage.getItem("fs-user")).name;
    const { firstName, name, profileImage } = UtilService.getLocalStorageItem(
      `${LOCAL_STORAGE_VAR}-user`,
    );
    const USER_PERMIT = UtilService.getLocalStorageItem(
      `${LOCAL_STORAGE_VAR}-userPermissions`,
    );

    const HeaderLinks = [
      {
        link: "/account/cart",
        title: <IntlMessages id="app.my-cart" />,
        // detailKey: `tracks.${trackTypeObj.CART}`,
        isActive: USER_PERMIT && USER_PERMIT?.CART?.view,
        basicDetailnull: true,
      },
      {
        link: "/account/watchlist",
        title: <IntlMessages id="app.my-watchlist" />,
        // detailKey: `tracks.${trackTypeObj.WATCHLIST}`,
        isActive: USER_PERMIT && USER_PERMIT?.WATCHLIST?.view,
        basicDetailnull: true,
      },
      // {
      //   link: "/account/office-list",
      //   title: <IntlMessages id="app.office-list" />,
      //   // detailKey: `tracks.${trackTypeObj.REQUEST}`,
      //   basicDetailnull: true,
      //   isActive: USER_PERMIT && USER_PERMIT?.OFFICE?.view,
      // },
      // {
      //   link: "/account/quote-list",
      //   title: <IntlMessages id="app.quote-list" />,
      //   basicDetailnull: true,
      //   // detailKey: `tracks.${trackTypeObj.QUOTE}`,
      //   isActive: USER_PERMIT && USER_PERMIT?.QUOTE?.view,
      // },
      {
        link: "/account/notes",
        title: <IntlMessages id="app.my-comment" />,
        // detailKey: `tracks.${trackTypeObj.COMMENT}`,
        basicDetailnull: true,
        isActive: USER_PERMIT && USER_PERMIT?.NOTES?.view,
      },
      {
        link: "/account/hold",
        title: <IntlMessages id="app.myHoldList" />,
        basicDetailnull: true,
        //isActive: USER_PERMIT && USER_PERMIT?.HOLD?.view
      },
      {
        link: "/account/memo",
        title: <IntlMessages id="app.myMemoList" />,
        basicDetailnull: true,
        //isActive: USER_PERMIT && USER_PERMIT?.MEMO?.view
      },
      {
        link: "/account/confirm-list",
        title: <IntlMessages id="app.confirm-list" />,
        // detailKey: `orderData.order`,
        basicDetailnull: true,
        isActive: USER_PERMIT && USER_PERMIT?.CONFIRM_STONE?.view,
      },
      // {
      //   link: "/my-bid",
      //   title: <IntlMessages id="app.myBid" />,
      //   basicDetailnull: true,
      //   isActive: USER_PERMIT && USER_PERMIT?.MY_BID?.view,
      // },
      {
        link: "/account/saved-search",
        title: <IntlMessages id="app.saved-search" />,
        basicDetailnull: true,
        isActive: USER_PERMIT && USER_PERMIT?.SAVE_SEARCH?.view,
      },
      {
        link: "/account/purchase",
        // title: <IntlMessages id="app.saved-search" />,
        title: "My Purchase",
        basicDetailnull: true,
        isActive: USER_PERMIT && USER_PERMIT?.SAVE_SEARCH?.view,
      },
      {
        link: "/account/memo",
        // title: <IntlMessages id="app.saved-search" />,
        title: "My Memo",
        basicDetailnull: true,
        isActive: USER_PERMIT && USER_PERMIT?.SAVE_SEARCH?.view,
      },
      {
        link: "/account/my-demand",
        title: <IntlMessages id="app.my-demand-tab" />,
        basicDetailnull: true,
        isActive: USER_PERMIT && USER_PERMIT?.DEMAND?.view,
      },

      {
        link: "/account/profile",
        title: <IntlMessages id="app.MyProfile" />,
        basicDetailnull: true,
        isActive: true,
      },

      {
        link: "/accounting",
        title: <IntlMessages id="app.accounts" />,
        basicDetailnull: true,
        isActive: true,
      },

      {
        link: "",
        title: <IntlMessages id="app.SignOut" />,
        onClick: () => {
          commonLogout();
        },
        basicDetailnull: true,
        isActive: true,
      },
    ];

    const PRICE_SELECTION = [
      { id: 2, name: "Update by Price per carat", key: "CTPR_USD" },
      { id: 3, name: "Update by Percentage", key: "CTPR_PERCENTAGE" },
    ];

    let extraTerms = localStorage.getItem(`${LOCAL_STORAGE_VAR}-extraTerms`)
      ? JSON.parse(
          window.atob(localStorage.getItem(`${LOCAL_STORAGE_VAR}-extraTerms`)),
        )
      : {};

    return (
      <>
        <div className="HeaderSticky">
          {this.state.covid && (
            <CovidStrip event={this.state.event} onClick={this.closeStrip} />
          )}
          <div className={`headerTopMenu ${this.state.covid ? "active" : ''}`}>
            {/* {this.state.HeaderStripList && (
              <UpDownBox onClick={this.HeaderStripCick} />
            )}
            {!this.state.HeaderStripList && (
              <UpDownBox downArrow onClick={this.HeaderStripCick} />
            )} */}
            <div className="headerWrapper">
              <div className="headerLeft">
                <div className="headerLogo">
                  <Link to="/dashboard">
                    <img
                      width="100%"
                      height="50px"
                      style={{ objectFit: "contain" }}
                      src={require("../assets/img/logo.png")}
                      alt="Belgium"
                    />
                  </Link>
                </div>
                <div className="headerMenuOption">
                  <Sidebar />
                </div>
                {/* <div className="headerSearchWrapper">
                <div className="headerSearch">
                  <input placeholder="Search " />
                  <div className="headerSearchIcon">
                    <img src={require("../assets/svg/Header/search.svg")} alt="" />
                  </div>
                  <div className="headerSearchIcon2">
                    <img
                      src={require("../assets/svg/Header/voice.svg")}
                      className="voiceSearchImg"
                    />
                  </div>
                </div>
              </div> */}
              </div>
              <div className="headerRightSide">
                <div
                  className={`headerRightBlock ${
                    btnClass || btnClass1 || btnClass2
                  }`}
                >
                  <div className="headerIcon" onClick={this.searchOption}>
                    <img
                      className="defulticon"
                      src={require("../assets/svg/Header/search.svg")}
                      alt="icon"
                    />
                    <img
                      className="hoverIcon"
                      src={require("../assets/svg/Header/search-white.svg")}
                      alt="icon"
                    />
                  </div>
                  <HeaderSearch
                    className={this.state.search ? "active" : ''}
                    onClick={this.searchOption}
                    searchList={this.state.searchList}
                  />
                  <div
                    className="headerRightOption"
                    onMouseEnter={() => this.handleHover()}
                    onClick={() => this.handleHover()}
                  >
                    <Link
                      to="/account/profile"
                      className="headerIcon userName header-icon-main"
                    >
                      <img
                        className="header-icon-different defulticon"
                        // src={require("../assets/svg/Header/user.svg")}
                        src={require("../assets/img/user.png")}
                        alt="icon"
                      />
                      <img
                        className=" hoverIcon "
                        src={require("../assets/svg/Header/user-white.svg")}
                        // src={require("../assets/img/user.png")}
                        alt="icon"
                      />
                      <span className="headerUserNameNotMore">
                        <IntlMessages id="app.Welcome" />, {firstName}
                      </span>
                    </Link>
                    <HeaderRightSidebar
                      activeClass={btnClass}
                      handleOut={this.handleOut}
                      onClick={this.handleOut}
                    >
                      <div className="dropdownUserIcon">
                        <div className="userProfileLogo">
                          {profileImage ? (
                            <img
                              src={`${BASE_URL_ATTACH}/${profileImage}`}
                              alt="user"
                            />
                          ) : (
                            <DefaultUser />
                            // <img src={require("../assets/img/user-default.png")} alt="user" />
                          )}
                        </div>

                        <div className="userProfileContent">
                          <h2>{name}</h2>
                          <p
                            onClick={() =>
                              window.location.assign("/account/profile")
                            }
                          >
                            <IntlMessages id="app.SeeYourProfile" />
                          </p>
                          {/* {!this.state.isGuest && (
                            <a
                              className="updatePriceLink"
                              onClick={() =>
                                !isEmpty(extraTerms)
                                  ? localStorage.removeItem(
                                      `${LOCAL_STORAGE_VAR}-extraTerms`
                                    )
                                  : this.setState({ updatePrice: true })
                              }
                            >
                              {!isEmpty(extraTerms)
                                ? "Remove Price"
                                : "Update Price"}
                            </a>
                          )} */}
                        </div>
                      </div>

                      <div className="userDetailList">
                        <ul>
                          <HeaderAccountDetails
                            headerLinks={HeaderLinks}
                            isGuest={this.state.isGuest}
                            showGuest={this.showGuest}
                            isHovered={this.state.isHovered}
                            {...this.props}
                          />
                          {/* {HeaderLinks.map((x, i) => {
                            return (
                              <HeaderAccountList
                                key={i}
                                isGuest={this.state.isGuest}
                                showGuest={this.showGuest}
                                {...this.props}
                                {...x}
                              />
                            );
                          })} */}
                        </ul>
                      </div>
                    </HeaderRightSidebar>
                  </div>
                  <div className="headerRightOption">
                    <div
                      className="headerIcon"
                      onMouseEnter={this.handleHover2}
                      onClick={this.handleHover2}
                    >
                      <img
                        className="defulticon"
                        src={require("../assets/svg/Header/notification.svg")}
                        alt="icon"
                      />
                      <img
                        className="hoverIcon"
                        src={require("../assets/svg/Header/notification-white.svg")}
                        alt="icon"
                      />
                      {this.state.notifications > 0 && (
                        <CountBox
                          {...this.props}
                          count={this.state.notifications}
                        />
                      )}
                    </div>
                    <HeaderRightSidebar
                      activeClass={btnClass2}
                      handleOut={this.handleOut2}
                      onClick={this.handleOut2}
                    >
                      <NotificationDropdown
                        count={this.state.notifications}
                        setUpdatedCount={(newCount) =>
                          this.setState({ notifications: newCount })
                        }
                      />
                    </HeaderRightSidebar>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="headerStrip"></div>
          {this.state.HeaderStripList && (
            <HeaderStrip
              // className={btnClass || btnClass1 || btnClass2}
              onClick={this.HeaderStripCick}
              rates={this.state.rates}
            />
          )}
          {this.state.showGuest && (
            <GuestUserPopup
              visible={this.state.showGuest}
              register={() => {
                window.open("/signup", "_self");
                window.localStorage.clear();
                window.sessionStorage.clear();
              }}
              handleCancel={() => {
                this.setState({ showGuest: false });
              }}
            />
          )}
          {this.state.updatePrice && !this.state.showGuest && (
            <CommonModal
              visible={this.state.updatePrice}
              handleOk={() => {
                let { selectPriceType, priceInput } = this.state;
                let addExtraTerms = {
                  termType: selectPriceType,
                  termValue: priceInput,
                };
                if (!selectPriceType) {
                  return OpenNotification({
                    type: "error",
                    title: "Please select update type.",
                  });
                } else if (!priceInput) {
                  return OpenNotification({
                    type: "error",
                    title: "Please enter price.",
                  });
                }
                localStorage.setItem(
                  `${LOCAL_STORAGE_VAR}-extraTerms`,
                  window.btoa(JSON.stringify(addExtraTerms)),
                );
                this.setState({ updatePrice: false });
              }}
              handleCancel={() =>
                this.setState({
                  updatePrice: !this.state.updatePrice,
                  priceInput: "",
                  selectPriceType: undefined,
                })
              }
              title="Update Price"
              footerShow
              submitTitle={<IntlMessages id="app.signup.Submit" />}
              cancelTitle={<IntlMessages id="app.Cancel" />}
            >
              <div className="searchPopupCommon">
                <div className="searchPopupTwoValue">
                  <SelectOption
                    placeholder="Please select type"
                    value={this.state.selectPriceType}
                    selectValue={PRICE_SELECTION}
                    onChange={(value) => {
                      this.setState({ selectPriceType: value });
                    }}
                    label={"Select Type"}
                  />

                  <InputBlock
                    onKeyPress={(event) => {
                      var regex = new RegExp("^[0-9]d*(.d+)?$");
                      var key = String.fromCharCode(
                        !event.charCode ? event.which : event.charCode,
                      );
                      if (!regex.test(key)) {
                        event.preventDefault();
                        return false;
                      }
                    }}
                    type="number"
                    label={"Value"}
                    placeholder={"Enter Value"}
                    value={this.state.priceInput}
                    onChange={(e) =>
                      this.setState({
                        priceInput: e.target.value
                          ? Number(e.target.value)
                          : "",
                      })
                    }
                  />
                </div>
              </div>
            </CommonModal>
          )}
        </div>
      </>
    );
  }
}

// export default Header;
function mapStateToProps(props) {
  return { data: props };
}

export default connect(mapStateToProps, { setLoader, covidStrip })(
  withRouter(Header),
);
